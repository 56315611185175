import { Button, LinkButton } from "~/components/Button";
import { GitHubIcon } from "~/components/Icons";
import SimpleNavbar from "~/components/SimpleNavbar";
import Container from "~/components/Container";
import { useNavigation } from "react-router-dom";
import Fieldset from "~/components/Fieldset";
import GridBackground from "~/components/GridBackground";
import StepCounter from "~/components/StepCounter";
import { Form } from "@remix-run/react";

type AuthLayoutProps = {
  title: string;
  description: string;
  action: string;
  cta: string;
  ctaHref: string;
  showCounter?: boolean;
};

export default function AuthLayout({
  title,
  description,
  action,
  cta,
  ctaHref,
  showCounter,
}: AuthLayoutProps) {
  const navigation = useNavigation();

  return (
    <>
      <GridBackground />

      <div className="grid min-h-screen grid-rows-layout">
        <SimpleNavbar
          logoHref="/"
          actionElement={
            <div>
              <LinkButton to={ctaHref} text={cta} theme="blank" size="MD" />
            </div>
          }
        />
        <Container>
          <div className="isolate flex h-full w-full items-center justify-center">
            <div className="-mt-16 max-w-2xl space-y-8">
              {showCounter ? (
                <div className="text-center">
                  <StepCounter currStepIdx={0} nSteps={2} />
                </div>
              ) : null}
              <div className="space-y-2 text-center">
                <h1 className="text-4xl font-semibold">{title}</h1>
                <p className="text-slate-600">{description}</p>
              </div>

              <div className="mx-auto max-w-sm space-y-4">
                <Form action="/github" method="POST">
                  <Fieldset>
                    {/*This could be the KVM ID*/}
                    <Button
                      size="LG"
                      theme="light"
                      fullWidth
                      text={`${action} with GitHub`}
                      LeadingIcon={GitHubIcon}
                      textAlign="center"
                      type="submit"
                      loading={
                        (navigation.state === "submitting" ||
                          navigation.state === "loading") &&
                        navigation.formMethod?.toLowerCase() === "post" &&
                        navigation.formAction?.includes("github")
                      }
                    />
                  </Fieldset>
                </Form>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
